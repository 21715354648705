





















































import { Vue, Prop, Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { Models } from '@mtap-smartcity/api';
import { ScenariosState } from '@/store/modules/scenarios/types';
import {
  UserGetter, UserGetterType
} from '@/store/modules/admin/types';

const scenarios = namespace('scenarios');
const admin = namespace('admin');

@Component
export default class ScenarioRuleActions extends Vue {
  @Prop(Object) readonly scenarioElement!: Models.ScenarioElements.Model

  @Prop(Number) readonly index!: number

  @Prop(Boolean) readonly draggingIsEnabled!: boolean

  @Prop(Number) readonly listLength!: number

  @Prop(Boolean) readonly isLastElement!: boolean

  @scenarios.State
  selectedScenarioHasConnections!: ScenariosState['selectedScenarioHasConnections'];

  @admin.Getter(UserGetter.GetPermissions)
  GetPermissions!: UserGetterType['GET_PERMISSIONS'];

  get permissionCheckWrite() {
    const getPermissions = this.GetPermissions.find((item) => item.type === 'scenarios');
    return getPermissions.write;
  }

  get permissionCheckDelete() {
    const getPermissions = this.GetPermissions.find((item) => item.type === 'scenarios');
    return getPermissions.delete;
  }

  edit(id: number) {
    this.$emit('editClicked', id);
  }

  deleteElement(scenarioElementUuid: string, graphUuid: string) {
    this.$emit('deleteClicked', { scenarioElementUuid, graphUuid });
  }

  changeOrder(oldPlace: number, newPlace: number) {
    if (newPlace < 0 || newPlace >= this.listLength) return;
    this.$emit('changeOrderClicked', { oldPlace, newPlace });
  }
}
